import styles from "./Button.module.css";
import PropTypes from "prop-types";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Link } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const ButtonArr = ({ title, link, onClick }) => {
  return (
    <Link onClick={onClick} to={link} className={`${styles.btnarr} flex gap-8 justify-center`}>
      <p>{title}</p>
      <NorthEastIcon className="icon-white little-margin-top" />
    </Link>
  );
};
ButtonArr.PropTypes = {
  title: PropTypes.string
};

export default ButtonArr;
